import { useEffect, useRef } from 'react'

import Analytics from './Analytics'

const ReactRouterPageLoad = (props) => {
  const referrerRef = useRef(document.referrer)
  const { pathname, search, hash } = props.location

  useEffect(() => {
    Analytics.screen('Page View', {
      path: pathname,
      hash,
      search,
      referrer: referrerRef.current,
    })

    referrerRef.current = `${pathname}${search}${hash}`
  }, [pathname, search, hash])

  return null
}

export default ReactRouterPageLoad
