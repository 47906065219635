declare global {
  interface Window {
    PBXX_APPLICATION_CONFIG: AppConfigVariables
    STATIC_CONFIG_DEPLOY_ENV: string
    STATIC_CONFIG_APP_NAME: string
  }
}

export interface AppConfigVariables {
  BASEPATH: string
  // NODE_ENV: string
  ENV: string
  BROWSER: boolean
  SERVICE_BASE: string
  API_BASE: string
  CP_BASE: string
  WWW_BASE: string
  RESET_PASSWORD_REDIRECT_PATH: string
  TERMS_PATH: string
  PRIVACY_PATH: string
  SEGMENT_KEY?: string
  NEW_RELIC_APP_ID?: string
  /** @deprecated */
  HELPSCOUT_BEACON_APP_ID?: string
  ZENDESK_WEB_WIDGET_ID?: string
  GOOGLE_ANALYTICS_KEY?: string
  GOOGLE_MAPS_API_KEY: string
  DEBUG_ANALYTICS: boolean
  LAUNCH_DARKLY_APP_ID: string
  CLOUD_NAME: string
  APOLLO_CLIENT: string
  ONE_TRUST_KEY?: string
  HONEYBADGER_API_KEY?: string
  SOCIAL_PREVIEW_APP_BASE: string
  FACEBOOK_APP_ID: string
  FACEBOOK_APP_CONFIGURATION?: string
  DATADOG_APPLICATION_ID?: string
  DATADOG_CLIENT_TOKEN?: string
  DATADOG_SITE?: string
  DATADOG_SERVICE?: string
  DATADOG_ENV?: string
  DATADOG_TRACING_ORIGINS?: string[]
  TRANSLATIONS_FILE_FILTER?: string
  TRANSLATIONS_DOMAIN?: string
  REFINER_PROJECT_ID?: string
  STRIPE_KEY: string
  OMNIAUTH_COOKIE_DOMAIN: string
  USE_OMNIAUTH_LOGIN: boolean
  OMNIAUTH_BASE: string
  COMMITHASH: string
}

const AppConfig: AppConfigVariables = {
  ...window.PBXX_APPLICATION_CONFIG,
}

export async function initConfig() {
  if (!window.localStorage.getItem('pbxxConfig')) return

  const urlParams = new URLSearchParams(window.location.search)
  const deployEnvParam = urlParams.get('deployEnv')
  const deployEnv =
    // If there is a `deployEnv` query param, use that.
    deployEnvParam
      ? deployEnvParam
      : // If we're in production and the STATIC_CONFIG_DEPLOY_ENV hasn't been set,
        // something has gone wrong with out build and we cannot continue.
        process.env.NODE_ENV === 'production' &&
          window.STATIC_CONFIG_DEPLOY_ENV === '__DEPLOY_ENV__'
        ? undefined
        : // If we're not in production and the STATIC_CONFIG_DEPLOY_ENV hasn't been
          // set, consider it the local environment.
          process.env.NODE_ENV !== 'production' &&
            window.STATIC_CONFIG_DEPLOY_ENV === '__DEPLOY_ENV__'
          ? 'local'
          : // Otherwise we can fall back to STATIC_CONFIG_DEPLOY_ENV.
            window.STATIC_CONFIG_DEPLOY_ENV

  if (deployEnv == null) {
    throw new Error('deployEnv must be set')
  }
  const configRes = await fetch(
    `https://config.promoboxx.com/brand/${window.encodeURIComponent(
      deployEnv,
    )}.json`,
    {
      method: 'GET',
    },
  )
  if (!configRes.ok) {
    throw Error(
      `an error has occurred fetching app config: ${configRes.status}`,
    )
  }

  const config = await configRes.json()
  // Need to cast here because our use of `delete` and setting variables from
  // the resolved config.
  const appConfigAny: any = AppConfig

  // We can't just set AppConfig to a new object, otherwise things that have
  // already imported the reference could have something stale.
  for (const key in AppConfig) {
    delete appConfigAny[key]
  }

  for (const key in config) {
    appConfigAny[key] = config[key]
  }

  // Helpful URLs for development
  // AppConfig.APOLLO_CLIENT = 'http://localhost:4000'
  // AppConfig.SOCIAL_PREVIEW_APP_BASE = 'https://localhost:10443'
  // Windows may need this, but it will also work on your mac side as well.
  // Note that if you are on a different WiFi,
  // you will have to make sure this is your current local IP.
  // SOCIAL_PREVIEW_APP_BASE: 'https://10.0.0.132:10443',
}

export default AppConfig
