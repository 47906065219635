import Honeybadger from 'honeybadger-js'

import AnalyticsProvider from './AnalyticsProvider'
class SegmentAnalytics extends AnalyticsProvider {
  constructor(analytics) {
    super()
    this.analytics = analytics
  }
  identify(userId, traits = {}, options = {}, callback) {
    const analytics = this.getAnalytics()
    try {
      return analytics.identify(userId, traits, options, callback)
    } catch (e) {
      Honeybadger.notify(
        new Error(
          'SegmentAnalytics is not implemented, could not run identify',
        ),
      )
    }
  }
  track(name, traits = {}) {
    const analytics = this.getAnalytics()
    try {
      return analytics.track(name, traits)
    } catch (e) {
      Honeybadger.notify(
        new Error('SegmentAnalytics is not implemented, could not run track'),
      )
    }
  }
  screen(name, traits = {}, category = 'user-screen') {
    const analytics = this.getAnalytics()
    try {
      return analytics.page(category, name, traits)
    } catch (e) {
      Honeybadger.notify(
        new Error('SegmentAnalytics is not implemented, could not run screen'),
      )
    }
  }
  group(groupId, traits = {}) {
    const analytics = this.getAnalytics()
    try {
      return analytics.group(groupId, traits)
    } catch (e) {
      Honeybadger.notify(
        new Error('SegmentAnalytics is not implemented, could not run group'),
      )
    }
  }
  getAnalytics() {
    return this.analytics || window.analytics
  }
}

export default SegmentAnalytics
