import { createSelector } from 'reselect'

import {
  currentBrandIdSelector,
  currentRetailerAccountSelector,
} from '../app/selectors'
import { currentRetailerIdSelector } from '../retailers/selectors'

export const selectTrackingData = createSelector(
  [
    currentRetailerIdSelector,
    currentBrandIdSelector,
    currentRetailerAccountSelector,
  ],
  (retailerId, brandId, retailerAccount) => ({
    retailerId,
    brandId,
    retailerAccountId: Number(retailerAccount && retailerAccount.id),
    retailerAccountName: retailerAccount && retailerAccount.name,
  }),
)
