import _refiner from 'refiner-js'

// Refinder identify. Refiner is hooked upt o Segment directly as a read/write client. We
// do not need to manually identify users in Refiner at all.
//
// Helpful information:
// See Refiner documentation here:
// https://refiner.io/docs/kb/getting-started/welcome/
//
// Turn on/off the debug mode: ?refiner_debug=true / ?refiner_debug=false
//
// Force a survey to appear when launching manually:
// const refiner = useRefinerSurvey()
// refiner('showForm', '<form-id-or-alias>');
// refiner('showForm', '<form-id-or-alias>', true); `true will force it for testing`
export const useRefinerSurvey = () => {
  //Not much here right now, but useful to have an abstract layer to support any changes / abstractions we want in the future.
  return {
    refiner: _refiner,
  }
}
