import AppConfig from '../config/AppConfig'
import { store } from '../config/Redux'
import { actions } from '../stores'

import Datadog from './providers/Datadog'
import DebugAnalytics from './providers/DebugAnalytics'
import GoogleAnalytics from './providers/GoogleAnalytics'
import SegmentAnalytics from './providers/SegmentAnalytics'

export class Analytics {
  constructor() {
    this.providers = []

    //Segment
    if (AppConfig.SEGMENT_KEY) {
      this.providers.push(new SegmentAnalytics(window.analytics))
    }

    //Google
    if (AppConfig.GOOGLE_ANALYTICS_KEY) {
      this.providers.push(new GoogleAnalytics(window.gtag))
    }

    //Debug
    if (AppConfig.NODE_ENV === 'development' && AppConfig.DEBUG_ANALYTICS) {
      this.providers.push(new DebugAnalytics())
    }

    this.providers.push(new Datadog())

    store.dispatch(actions.analytics.initializeAnalytics({ analytics: this }))
  }
  async identify(userId, traits = {}, options = {}, callback) {
    return Promise.all(
      this.providers.map((provider) =>
        provider.identify(userId, traits, options, callback),
      ),
    )
  }
  async track(name, traits = {}, category = 'user-event') {
    return Promise.all(
      this.providers.map((provider) => provider.track(name, traits, category)),
    )
  }
  async screen(name, traits = {}, category = 'user-screen') {
    return Promise.all(
      this.providers.map((provider) => provider.screen(name, traits, category)),
    )
  }
  async group(groupId, traits = {}) {
    return Promise.all(
      this.providers.map((provider) => provider.group(groupId, traits)),
    )
  }
}

const instance = new Analytics()

export default instance
