import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { lightTheme, darkTheme } from './theme'

/** @type {React.FC<ThemeProviderProps>} */
const ThemeProvider = (props) => {
  const theme = props.mode === 'light' ? lightTheme : darkTheme

  return (
    <MuiThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>{props.children}</StyledThemeProvider>
    </MuiThemeProvider>
  )
}

/**
 * @typedef {{
 *  mode: 'light' | 'dark'
 * }} ThemeProviderProps
 */

export default ThemeProvider
