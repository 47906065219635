import PropTypes from 'prop-types'
import styled from 'styled-components'

import LegacySvg from '../LegacySvg'
import Loading from '../Loading'

const StyledLoader = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Loader = (props) => {
  return (
    <StyledLoader>
      <LegacySvg
        height={`${props.height}px`}
        symbol={props.icon}
        width={`${props.width}px`}
      />
      <Loading />
    </StyledLoader>
  )
}

Loader.propTypes = {
  height: PropTypes.number,
  icon: PropTypes.string,
  width: PropTypes.number,
}

Loader.defaultProps = {
  height: 200,
  icon: 'boxx-blue',
  width: 200,
}

export default Loader
