import AppConfig from './AppConfig'

export default function initOneTrust() {
  if (
    !AppConfig.ONE_TRUST_KEY ||
    new URL(window.location.toString()).searchParams.get('no_cookie_banner') ===
      'true' ||
    new URL(window.location.toString()).searchParams.get(
      'suppress_overlays',
    ) === 'true'
  ) {
    return
  }

  const script = document.createElement('script')
  script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
  script.type = 'text/javascript'
  script.charset = 'UTF-8'
  script.setAttribute('data-domain-script', AppConfig.ONE_TRUST_KEY)

  window.OptanonWrapper = () => {}

  document.head.appendChild(script)
}
