import AppConfig from '../../config/AppConfig'

import AnalyticsProvider from './AnalyticsProvider'

class GoogleAnalytics extends AnalyticsProvider {
  constructor(analytics) {
    super()
    this.analytics = analytics
    this.trackingId = AppConfig.GOOGLE_ANALYTICS_KEY
  }
  identify(userId, traits = {}) {
    this.analytics &&
      this.analytics('config', this.trackingId, {
        user_id: userId,
        ...traits,
      })
  }
  track(name, props = {}) {
    this.analytics && this.analytics('event', name, props)
  }
  screen(screen_name, props = {}, category = 'screen_view') {
    this.analytics &&
      this.analytics('event', category, {
        ...props,
        screen_name,
      })
  }
}

export default GoogleAnalytics
