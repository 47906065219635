import { createBrowserHistory, History } from 'history'

import AppConfig from './AppConfig'

let history: History

export const initHistory = () => {
  history = createBrowserHistory({
    basename: AppConfig.BASEPATH,
    getUserConfirmation: () => {}, // allows native browser prompt to be bypassed for RouterPrompt component
  })
}

const historyRef = {
  instance: () => history,
}

export default historyRef
