import { datadogRum } from '@datadog/browser-rum'

import AnalyticsProvider from './AnalyticsProvider'

class Datadog extends AnalyticsProvider {
  identify(userId, traits) {
    let fullStorySession = ''
    if (window.FS && window.FS.getCurrentSession) {
      fullStorySession = window.FS.getCurrentSession()
    }
    datadogRum.setUser({
      ...traits,
      id: userId,
      name: `${traits.firstName} ${traits.lastName}`,
      email: traits.email,
      brand: traits.brandName,
      fullStorySession,
    })
  }
}

export default Datadog
