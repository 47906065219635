import AppConfig from './AppConfig'

let helpScountBeaconInitialized = false

export default function initHelpScoutBeacon() {
  if (
    helpScountBeaconInitialized ||
    !AppConfig.HELPSCOUT_BEACON_APP_ID ||
    new URL(window.location.toString()).searchParams.get(
      'suppress_overlays',
    ) === 'true'
  ) {
    return
  }

  /* prettier-ignore */
  /* eslint-disable */
  !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
  /* eslint-enable */

  window.Beacon('init', AppConfig.HELPSCOUT_BEACON_APP_ID)
}
