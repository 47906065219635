import AppConfig from './AppConfig'

export default function initZendesk() {
  if (
    !AppConfig.ZENDESK_WEB_WIDGET_ID ||
    new URL(window.location.toString()).searchParams.get(
      'suppress_overlays',
    ) === 'true'
  ) {
    return
  }

  const script = document.createElement('script')
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${AppConfig.ZENDESK_WEB_WIDGET_ID}`
  script.type = 'text/javascript'
  script.id = 'ze-snippet'

  document.head.appendChild(script)
}
