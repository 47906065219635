import AnalyticsProvider from './AnalyticsProvider'

class DebugAnalytics extends AnalyticsProvider {
  identify(userId, traits = {}) {
    console.info('[ANALYTICS] identify: ', userId, traits)
  }
  track(name, traits = {}, category = 'user-event') {
    console.info('[ANALYTICS] track: ', name, traits, category)
  }
  screen(name, traits = {}, category = 'user-screen') {
    console.info('[ANALYTICS] screen: ', name, traits, category)
  }
  group(groupId, traits = {}) {
    console.info('[ANALYTICS] group: ', groupId, traits)
  }
}

export default DebugAnalytics
