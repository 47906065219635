// prettier-ignore
import AppConfig from './AppConfig';

export default function initSegmentAnalytics() {
  if (!AppConfig.SEGMENT_KEY) {
    return
  }

  /* eslint-disable */
  !(function () {
    var analytics = (window.analytics = window.analytics || [])
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.')
      else {
        analytics.invoked = !0
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
        ]
        analytics.factory = function (t) {
          return function () {
            var e = Array.prototype.slice.call(arguments)
            e.unshift(t)
            analytics.push(e)
            return analytics
          }
        }
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t]
          analytics[e] = analytics.factory(e)
        }
        analytics.load = function (t, e) {
          var n = document.createElement('script')
          n.type = 'text/javascript'
          n.async = !0
          n.src =
            'https://telemetry-cdn.promoboxx.com/analytics.js/v1/' +
            t +
            '/analytics.min.js'
          var a = document.getElementsByTagName('script')[0]
          a.parentNode.insertBefore(n, a)
          analytics._loadOptions = e
        }
        analytics.SNIPPET_VERSION = '4.1.0'
        window.analytics.load(AppConfig.SEGMENT_KEY)
      }
  })()
  /* eslint-enable */

  const isLoaded = (resolve, timeout) => {
    if (
      typeof window.analytics === 'object' &&
      typeof window.analytics.init === 'function' //There may be a better way to confirm this is hydrated.
    ) {
      clearTimeout(timeout) //Clean up that timeout, not sure this is needed?
      resolve()
    } else {
      setTimeout(() => {
        isLoaded(resolve)
      }, 50) //Trying to strike balance between checking often, but not too often.
    }
  }

  return new Promise((resolve) => {
    let timeout = setTimeout(() => {
      resolve()
    }, 5000) //Fallback that will resolve after 5 seconds...
    isLoaded(resolve, timeout)
  })
}
