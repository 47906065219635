import { identifyUser } from '@promoboxx/redux-stores/src/stores/analytics/actions'
import {
  VALIDATE_AUTH_FULFILLED,
  SIGN_IN_FULFILLED,
} from '@promoboxx/redux-stores/src/stores/auth/actions'
import { userSelector } from '@promoboxx/redux-stores/src/stores/auth/selectors'
import { ofType } from 'redux-observable'
import { of, merge } from 'rxjs'
import { mergeMap, withLatestFrom } from 'rxjs/operators'

import AppConfig from '../../config/AppConfig'
import * as actions from '../app/actions'

export const analyticsIdentifyUser = (action$, state$) => {
  return action$.pipe(
    ofType(
      actions.SELECT_RETAILER_ACCOUNT,
      VALIDATE_AUTH_FULFILLED,
      SIGN_IN_FULFILLED,
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const user = userSelector(state)

      let userInfo = {
        userId: null,
      }

      if (user) {
        let brandNames = []
        let brandIds = []
        let industryList = []
        let retailerAccountNames = []
        let retailerAccountIds = []
        let retailerIds = []

        if (user.retailer_accounts) {
          user.retailer_accounts.forEach((retailerAccount) => {
            if (retailerAccount) {
              const retailerAccountName = retailerAccount.name
              const retailerAccountId = retailerAccount.id
              if (!retailerAccountIds.includes(retailerAccountId)) {
                retailerAccountNames.push(retailerAccountName)
                retailerAccountIds.push(String(retailerAccountId))
              }
              if (retailerAccount.retailers) {
                retailerAccount.retailers.forEach((retailer) => {
                  if (retailer && retailer.brand) {
                    const brandName = retailer.brand.name
                    const brandId = String(retailer.brand.id)
                    // This whole snippet was written by GitHub Copilot
                    retailer.brand.industry_list &&
                      retailer.brand.industry_list.forEach((industry) => {
                        if (!industryList.includes(industry)) {
                          industryList.push(industry)
                        }
                      })
                    const retailerId = String(retailer.id)
                    if (!brandIds.includes(brandId)) {
                      brandNames.push(brandName)
                      brandIds.push(brandId)
                    }
                    retailerIds.push(retailerId)
                  }
                })
              }
            }
          })
        }

        userInfo.userId = user.id
        userInfo.traits = {
          id: user.id,
          email: user.email,
          createdAt: user.created_at,
          type: user.type,
          env: AppConfig.ENV,
          app: 'retailer',
          uuid: user.uuid,
          timeZone: user.time_zone,
          timeZoneAbbr: user.time_zone_abbr,
          name: `${user.first_name} ${user.last_name}`,
          firstName: user.first_name,
          lastName: user.last_name,
          languagePreference: user.language_preference || 'en-us',
          roles: user.user_roles,
          industryList,
          brandNames,
          brandIds,
          retailerAccountNames,
          retailerAccountIds,
          retailerIds,
        }
      }
      return merge(of(identifyUser(userInfo)))
    }),
  )
}
