import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const facebookAnimation = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
`

const DefaultLoading = styled.div`
  &,
  &:after {
    border-radius: 50%;
    width: 4em;
    height: 4em;
  }
  margin: 0 auto;
  font-size: ${(props) => props.size}px;
  position: relative;
  text-indent: -9999em;
  border-top: ${(props) => props.size / 4}px solid rgb(0, 0, 0, 0.2);
  border-right: ${(props) => props.size / 4}px solid rgb(0, 0, 0, 0.2);
  border-bottom: ${(props) => props.size / 4}px solid rgb(0, 0, 0, 0.2);
  border-left: ${(props) => props.size / 4}px solid ${(props) => props.color};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${rotateAnimation} 1.1s infinite linear;
  animation: ${rotateAnimation} 1.1s infinite linear;
`

const LinesLoading = styled.div`
  color: ${(props) => props.color};
  text-indent: -9999em;
  margin: 0 auto;
  position: relative;
  font-size: ${(props) => props.size}px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  &,
  &:before,
  &:after {
    background: ${(props) => props.color};
    -webkit-animation: ${facebookAnimation} 1.2s infinite ease-in-out;
    animation: ${facebookAnimation} 1.2s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  &:before,
  &:after {
    position: absolute;
    bottom: 0;
    top: 0;
    content: '';
  }
  &:before {
    left: -1.5em;
    -webkit-animation-delay: -0.12s;
    animation-delay: -0.12s;
  }
  &:after {
    left: 1.5em;
    -webkit-animation-delay: 0.12s;
    animation-delay: 0.12s;
  }
`

const Loading = (props) => {
  switch (props.type) {
    case 'lines':
      return <LinesLoading color={props.color} size={props.size} />
    case 'default':
    default:
      return <DefaultLoading color={props.color} size={props.size} />
  }
}
Loading.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.oneOf(['default', 'lines']),
}

Loading.defaultProps = {
  type: 'default',
  color: '#F4F4F4',
  size: 20,
}

export default Loading
