import { ApolloProvider } from '@apollo/client'
import { CloudinaryContext } from 'cloudinary-react'
import { SnackbarProvider } from 'notistack'
import PropTypes from 'prop-types'
import { lazy, Suspense, useEffect, useRef } from 'react'
import { Route, Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import ForceScrollbar from '@src/lib/ForceScrollbar'
import {
  SimpleConfirmDialog,
  SimpleConfirmDialogProvider,
} from '@src/lib/SimpleConfirmDialog'

import ReactRouterPageLoad from '../analytics/ReactRouterPageLoad'
import { apolloClient } from '../config/ApolloClient'
import AppConfig from '../config/AppConfig'
import history from '../config/history'
import ThemeProvider from '../lib/ThemeProvider'

import AuthHandler from './auth/AuthHandler'
import AppInitializer from './shared/AppInitializer'
import Loader from './shared/Loader'

// Since our translations aren't reactive, we need to hold off on importing a
// large chunk of our app until a user is loaded and the translation system is
// fully set up.
const BaseRoutes = lazy(() => import('./routing/BaseRoutes'))

const App = (props) => {
  const { persistor } = props
  const previousLocationRef = useRef({
    pathname: '',
    search: '',
  })

  useEffect(() => {
    const unlisten = history.instance().listen(({ pathname, search }) => {
      if (
        (pathname.includes('/retailer/content/') &&
          pathname.includes('paid-ad')) ||
        (previousLocationRef.current.pathname.includes('/retailer/content/') &&
          previousLocationRef.current.pathname.includes('paid-ad')) ||
        search.includes('shareSettingId=') ||
        previousLocationRef.current.search.includes('shareSettingId=')
      ) {
        previousLocationRef.current = { pathname, search }
        return
      }

      previousLocationRef.current = { pathname, search }

      //This method scrolls the page to the top when a route is changed.
      //Otherwise in a SPA the page might by default stay scrolled to its current position.
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      })
    })

    return unlisten
  }, [])

  return (
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history.instance()}>
        <ApolloProvider client={apolloClient()}>
          <AuthHandler>
            <CloudinaryContext cloudName={AppConfig.CLOUD_NAME} includeOwnBody>
              <AppInitializer>
                <ThemeProvider mode="light">
                  <SnackbarProvider
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    maxSnack={3}
                  >
                    <SimpleConfirmDialogProvider>
                      <>
                        <ForceScrollbar />
                        <Route component={ReactRouterPageLoad} path="/" />
                        <Suspense fallback={<Loader />}>
                          <BaseRoutes />

                          <SimpleConfirmDialog />
                        </Suspense>
                      </>
                    </SimpleConfirmDialogProvider>
                  </SnackbarProvider>
                </ThemeProvider>
              </AppInitializer>
            </CloudinaryContext>
          </AuthHandler>
        </ApolloProvider>
      </Router>
    </PersistGate>
  )
}

App.propTypes = {
  persistor: PropTypes.object,
}

App.defaultProps = {}

export default App
